<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12" v-if="pageLoaded">
      <v-card-title
        style="background-color: transparent !important"
        class="ps-0 pe-0"
      >
        <v-row style="padding-left: 10px !important">
          <v-col class="text-right" md="6" cols="12">
            <h3>لیست دندانپزشکان</h3>
          </v-col>
          <v-col md="6" cols="12">
            <v-btn
              class="primary-btn float-end me-5"
              @click="addFreeDialog = true"
              >افزودن زمان آزاد</v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>

      <v-card class="cards pa-2">
        <v-card-text>
          <!--<div class="mb-1 pa-2 pb-5">
            <div class="h4">
              <span class="h3">
                <v-icon class="primary--text">mdi-flare</v-icon>
                برای مشاهده لیست پزشکان، ابتدا نوع تخصص را مشخص نمایید:
              </span>
            </div>
            <v-btn
              @click="
                roleFilter = item;
                CurrentPage = 1;
              "
              class="secondary-btn pa-5 me-2 mt-4"
              v-for="(item, index) in employeeExpertises"
              :key="index"
              :class="roleFilter == item ? 'primary-btn' : 'secondary-btn'"
              :disabled="!Items.filter((x) => x.expertise == item).length"
            >
              <span
                >{{
                  item +
                  " (" +
                  Items.filter((x) => x.expertise == item).length +
                  ")"
                }}
              </span>
            </v-btn>
          </div>-->
          <div class="px-2">
            <div class="h4 mb-4">
              <span class="h3">
                <v-icon class="green--text">mdi-comment-alert-outline</v-icon>
                سطر‌هایی که به رنگ سبز مشخص شده‌اند، دندانپزشکانی هستند که زمان‌
                آزاد برای آنها تعریف شده‌ است.</span
              >
            </div>
            <v-text-field
              v-model="Filter"
              prepend-inner-icon="mdi-magnify"
              label="جستجو"
              filled
              rounded
              single-line
              clearable
              hide-details
              class="text-right mb-2 search-input dr-search ms-0 me-0"
            ></v-text-field>
            <b-table
              responsive
              show-empty
              :fields="computedFields"
              :items="chosenDoctors"
              empty-text="پزشکی برای نمایش وجود ندارد"
              empty-filtered-text="پزشکی برای نمایش وجود ندارد"
              :busy="Busy"
              :filter="Filter"
              @filtered="onFiltered"
              :current-page="CurrentPage"
              :per-page="PerPage"
              :tbody-tr-class="rowClass"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>
              <template v-slot:cell(operation)="data">
                <div style="text-align: center; vertical-align: middle">
                  <v-tooltip bottom color="primary" v-if="!data.item.freeTimes">
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="disabled-btn mx-auto"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span> فاقد عملیات </span>
                      </div>
                    </template>
                    <span
                      >دندانپزشک فاقد زمان آزاد است، برای ثبت نوبت ابتدا برای
                      دندانپزشک زمان آزاد تعریف کنید</span
                    >
                  </v-tooltip>
                  <div v-else>
                    <v-btn
                      class="green-btn py-2 px-4"
                      style="width: 20rem"
                      @click="goToCalendar(data.item.id)"
                      target="_blank"
                    >
                      <span>
                        <v-icon class="me-2"> fa fa-calendar-plus-o </v-icon>

                        نوبت دهی
                      </span>
                    </v-btn>
                  </div>
                </div>
              </template>
              <div slot="table-busy" class="text-center primary--text my-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="align-middle"
                ></v-progress-circular>
              </div>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="
                Filter
                  ? Math.ceil(filteredchosenDoctors / PerPage)
                  : Math.ceil(chosenDoctors.length / PerPage)
              "
              :total-visible="5"
              @input="oldCurrentPage = CurrentPage"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
              style="float: center"
            ></v-pagination>
            <v-select
              label="تعداد در هر صفحه:"
              style="width: 150px"
              v-model="PerPage"
              :items="perPageOptions"
              item-text="text"
              item-value="value"
            ></v-select>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="addFreeDialog" width="600px">
      <v-card style="border-radius: 20px" class="px-5 py-3">
        <v-card-title class="ps-2">
          <span class="h1"> افزودن زمان آزاد</span>
        </v-card-title>
        <v-card-text class="p-0">
          <v-container>
            <v-row class="time-row">
              <v-col>
                <span v-show="false"> {{ reactivity }}</span>
                <v-select
                  v-model="docId"
                  :items="chosenDoctors"
                  item-text="name"
                  item-value="id"
                  label="نام پزشک"
                  class="pa-0 mb-2"
                  outlined
                  dense
                  @change="reactivity = !reactivity"
                ></v-select>
                <span v-if="computedDuration" class="red--text h4">
                  <v-icon small class="red--text"
                    >mdi-alert-circle-outline</v-icon
                  >
                  برای پزشک انتخاب شده مدت زمان ویزیت تعریف نشده است، با مدیر
                  سامانه تماس بگیرید.
                </span>
              </v-col>
            </v-row>
            <v-row class="time-row">
              <v-col md="6" cols="12">
                <span id="freeDateFrom">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="freeDateFrom"
                    :editable="true"
                    class="date-input"
                    label="تاریخ از"
                  >
                  </v-text-field>
                </span>
                <date-picker
                  v-model="freeDateFrom"
                  element="freeDateFrom"
                  color="#00a7b7"
                  :min="currentDate"
                />
              </v-col>
              <v-col md="6" cols="12">
                <span id="freeDateTo">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="freeDateTo"
                    :editable="true"
                    class="date-input"
                    label="تاریخ تا"
                  >
                  </v-text-field>
                </span>
                <date-picker
                  v-model="freeDateTo"
                  element="freeDateTo"
                  color="#00a7b7"
                  :min="currentDate"
                />
              </v-col>
            </v-row>
            <v-row class="time-row">
              <v-col md="6" cols="12">
                <span id="freeTimeFrom">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="freeTimeFrom"
                    :editable="true"
                    class="date-input"
                    label="ساعت از"
                  >
                  </v-text-field>
                </span>
                <date-picker
                  v-model="freeTimeFrom"
                  element="freeTimeFrom"
                  color="#00a7b7"
                  type="time"
                />
              </v-col>
              <v-col md="6" cols="12">
                <span id="freeTimeTo">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="freeTimeTo"
                    :editable="true"
                    class="date-input"
                    label="ساعت تا"
                  >
                  </v-text-field>
                </span>
                <date-picker
                  v-model="freeTimeTo"
                  element="freeTimeTo"
                  color="#00a7b7"
                  type="time"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="ms-auto">
            <v-btn
              class="mb-2 primary-btn"
              :loading="isBusy"
              @click="addNew()"
              :disabled="!docId || computedDuration"
              >ثبت
            </v-btn>

            <v-btn
              class="red-btn mb-2 mr-2 text-light"
              @click="addFreeDialog = false"
              >لغو
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showResultDialog" width="600">
      <v-card style="border-radius: 20px" class="modal-card px-3 py-3">
        <v-card-title>
          <span class="h1 primary--text">نتیجه افزودن زمان آزاد</span>
        </v-card-title>
        <v-card-text class="pa-5 pb-0">
          <v-container>
            <v-row>
              <v-col class="h3 font-weight-bold primary--text"
                >انجام شده: {{ result.done }}</v-col
              >
              <v-col class="h3 font-weight-bold primary--text"
                >انجام نشده: {{ result.undone }}</v-col
              >
            </v-row>
            <hr />
            <div v-for="(item, index) in result.newResults" :key="index">
              <v-row>
                <v-col class="h3">زمان آغاز:</v-col>
                <v-col class="h3">{{ item.startTime }}</v-col>
              </v-row>
              <v-row>
                <v-col class="h3">زمان پایان:</v-col>
                <v-col class="h3">{{ item.finishTime }}</v-col>
              </v-row>
              <v-row>
                <v-col class="h3">وضعیت:</v-col>
                <v-col class="h3" style="line-height: 30px">{{
                  item.text
                }}</v-col>
              </v-row>
              <hr />
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions class="pt-0">
          <div class="ms-auto">
            <v-btn color="primary-btn" @click="showResultDialog = false">
              <v-icon class="me-1">mdi-close</v-icon>
              بستن
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { mapGetters } from "vuex";
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      CurrentPage: 1,
      oldCurrentPage: 1,
      oldPerPage: 10,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      Filter: "",
      Busy: false,
      isBusy: false,
      filterCode: "",
      filterName: "",
      filterNatCode: "",
      role: "",
      Fields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام و نام خانوادگی" },
        //{ key: "expertise", label: "تخصص" },
        { key: "docCode", label: "کد نظام پزشکی" },
        { key: "operation", label: "عملیات " },
      ],
      Items: [],
      excelItems: [],
      addFreeDialog: false,
      freeDateFrom: moment(new Date()).format("jYYYY-jMM-jDD"),
      freeDateTo: moment(new Date()).format("jYYYY-jMM-jDD"),
      freeTimeFrom: moment(new Date()).format("HH:mm"),
      freeTimeTo: moment(new Date()).add(2, "hours").format("HH:mm"),
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      reactivity: false,
      docId: "",
      showResultDialog: false,
      result: {},
      chosenEmployee: false,
      employeeExpertises: [],
      roleFilter: "",
      filteredchosenDoctors: 0,
      pageLoaded: false,
    };
  },
  methods: {
    getDoctors() {
      this.Busy = true;
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/visitTimes/doctors",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.Items = res.data.doctors;
            this.employeeExpertises = res.data.expertises;
            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
            this.pageLoaded = true;
            this.vLoading = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
        });
    },
    addNew() {
      this.isBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/visitTimes/newVisitTime",
          {
            userId: this.docId,
            startDate: this.freeDateFrom + this.freeTimeFrom,
            endDate: this.freeDateTo + this.freeTimeTo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.isBusy = false;
          if (res.status == 201) {
            this.addFreeDialog = false;
            this.docId = "";
            this.freeDateFrom = moment(new Date()).format("jYYYY-jMM-jDD");
            this.freeDateTo = moment(new Date()).format("jYYYY-jMM-jDD");
            this.freeTimeFrom =
              moment(new Date().getHours()) +
              ":" +
              moment(new Date().getMinutes());
            this.freeTimeTo = moment(new Date())
              .add(2, "hours")
              .format("HH:mm");
            this.toast("زمان مورد نظر با موفقیت به ثبت رسید.", "success");
            this.result = res.data;
            this.showResultDialog = true;
            this.getDoctors();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");

          this.isBusy = false;
        });
    },
    goToCalendar(id) {
      let link = "";
      link = "/dentistryNurse/submitBooking/" + id;
      window.open(link, "_blank");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.filteredchosenDoctors = filteredItems.length;
      this.CurrentPage = 1;
    },
    rowClass(item) {
      if (item && item.freeTimes) return "table-row";
    },
  },
  computed: {
    ...mapGetters(["drNames"]),
    chosenDoctors() {
      let filtered = [];
      filtered = this.Items.filter((x) => x.expertise == "دندانپزشک");
      return filtered;
    },
    computedDuration() {
      if (this.docId) {
        if (
          this.drNames.filter((x) => x.id == this.docId)[0].visitDuration ==
          false
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
        computedFields() {
      if (this.deviceType == "mobile") {
        return [
          { key: "name", label: "نام و نام خانوادگی" },
          { key: "operation", label: "عملیات " },
        ];
      } else {
        return [
          { key: "index", label: "#" },
          { key: "name", label: "نام و نام خانوادگی" },
          { key: "docCode", label: "کد نظام پزشکی" },
          { key: "operation", label: "عملیات " },
        ];
      }
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");
    this.getDoctors();
  },
};
</script>

<style lang="scss">
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 80%;
}
.modal-card {
  max-height: 580px;
  overflow-y: scroll;
}
.table-row {
  background-color: #b2dfdb91 !important;
}
</style>
